<template>
    <div class="navBarWrapper"> 
 
        <div class="leftSide">

            <div >

                <!-- <span class="accountType">Account Type: Main Manager</span> -->


            </div>

            

        </div>

        <div class="rightSide">

            <div class="usernameBox">

                
                
                Hi, {{ authStore.user.name }}
            </div>

       

            <div  class="profilePic">


                <i  class="bi bi-person-circle" style="font-size: 30px; color: #959795;"></i>

            </div> 

            

        </div>



        

    </div>
</template>
 
<script setup>
import { useAuthStore } from '@/stores/AuthStore';

const authStore = useAuthStore();
 



</script>

<style scoped>

.navBarWrapper{
    /* background-color: rgb(255, 255, 255); */
    width: 100%;
    padding: 27px;
    display: flex;              /* Enables Flexbox */
    justify-content: space-between; /* Space between items */
    align-items: center;       /* Align items vertically */
    border-bottom: 0.89px solid #D9D9D9; 
    height: 90px;
   

}

.imgLogo{
    width: 110px;
}

.rightSide {
    display: flex;            /* Enable Flexbox */
    align-items: center;      /* Center items vertically */
    justify-content: center;  /* Center items horizontally */
}

.usernameBox, .downArrow, .profilePic {
    display: flex;
    align-items: center;      /* Center content of each box vertically */
    justify-content: center;  /* Center content of each box horizontally */
    margin: 0 5px;            /* Optional: add some space between the items */
}

/* Additional specific styles for each class if needed */
.profilePic {
    font-size: 30px;
    color: #959795;
    cursor: pointer;
}


.roundProfilePic {
    border-radius: 60%; 
    border: 1.5px solid #c3d4cb; 
    object-fit: cover; /* Ensures the image covers the area without stretching */
    width: 35px; /* Set the width as required */
    height: 35px; /* Set the height as required */
}


.accountType{
    font-size: 13px;
}

</style>