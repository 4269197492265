<template>
    <div style="overflow-x: hidden;">

        <div class="motherBox">

            <div class="leftBox" @click="scrollLeft">
                <div class="theChevBox">
                    <i class="bi bi-chevron-left theChev"></i>
                </div>
            </div>

            <div class="centerBox"></div>

            <div class="rightBox" @click="scrollRight">
                <div class="theChevBox">
                    <i class="bi bi-chevron-right theChev"></i>
                </div>
            </div>
        </div>


        <div class="picContentContainer" ref="picContainerRing">

            <div v-for="(cake) in weddingStore.cakePics" :key="cake.id" class="imgItem">
                <img class="thePic" :src="require(`@/assets/cakes/${cake.img_name}.webp`)" />
            </div>



        </div>
    </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { useWeddingStore } from '@/stores/WeddingStore';
  
  const weddingStore = useWeddingStore();

const picContainerRing = ref(null);

const scrollLeft = () => {
    if (picContainerRing.value) {
        picContainerRing.value.scrollBy({
        left: -200, // Adjust the scroll amount as needed
        behavior: 'smooth'
      });
    }
  };
  
  const scrollRight = () => {
    if (picContainerRing.value) {
        picContainerRing.value.scrollBy({
        left: 200, // Adjust the scroll amount as needed
        behavior: 'smooth'
      });
    }
  };



</script>

<style scoped>
.picContentContainer {
    position: relative;
    z-index: 1;
    display: flex;
    width: 100%;
    padding: 10px;
    flex-wrap: nowrap;
    overflow: hidden;
}

.imgItem {
    width: 200px;
    height: 400px;
    margin: 5px;
    flex: 0 0 auto;
}

.thePic {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.motherBox {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    display: flex;
    width: 100%;
    height: 420px;
}

.leftBox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    height: 100%;
  }
  
  .centerBox {
    width: 60%;
    background-color: transparent;
    height: 100%;
  }
  
  .rightBox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    height: 100%;
  }

.theChevBox {
    width: 50px;
    height: 50px;
    padding: 0;
    border-radius: 50%;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .theChev {
    font-size: 20px; /* Adjust size as needed */
    font-weight: bolder;
    color: white;
  }
</style>


<!-- <template>

    <div style="overflow-x: hidden;">


        <div class="motherBox">

            <div class="leftBox">


            </div>

            <div class="centerBox">


            </div>

            <div class="rightBox">


            </div>

        </div>


        <div class="picContentContainer">



            <div v-for="(cake) in cakeGeneratorStore.cakePics.concat(cakeGeneratorStore.cakePics)" :key="cake.id"
                class="imgItem">
                <img class="thePic" :src="require(`@/assets/cakes/${ cake.img_name }.webp`)" />
            </div>




        </div>





    </div>
</template>

<script setup>
import { useCakeGeneratorStore } from '@/stores/CakeGeneratorStore';
import { computed } from 'vue';



const cakeGeneratorStore = useCakeGeneratorStore();

// Calculate total width of content
const totalWidth = computed(() => {
const imgWidth = 200; // Width of each image
const margin = 5 * 2; // Margin on both sides
return cakeGeneratorStore.cakePics.length * (imgWidth + margin);
});

// Calculate animation duration based on total width
const animationDuration = computed(() => {
const scrollSpeed = 400; // Adjust this value to control speed
return totalWidth.value / scrollSpeed;
});

</script>

<style scoped>

.picContentContainer {
position: relative;
z-index: 1;
display: flex;
width: 100%;
padding: 10px;
flex-wrap: nowrap;
/* animation: scroll-right 7.5s linear infinite; */
animation: scroll-right v-bind(animationDuration + 's') linear infinite;

}


.imgItem {
width: 200px;
height: 400px;
/* background-color: yellow; */
margin: 5px;
flex: 0 0 auto;

}

.thePic {
width: 100%;
height: 100%;
object-fit: cover;
border-radius: 10px;
box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}


@keyframes scroll-right {
0% {
    transform: translateX(0);
}

100% {
    transform: translateX(-50%);
}
}

.motherBox {
position: absolute;
top: 0;
left: 0;
right: 0;
z-index: 2;
display: flex;
width: 100%;


height: 420px;
}

.leftBox {
width: 10%;
background-image: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
height: 100%;
}

.centerBox {
width: 80%;
background-color: transparent;
height: 100%;
}

.rightBox {
width: 10%;
background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
height: 100%;
}


</style> -->