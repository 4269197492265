<template>
    <div style="overflow-x: hidden;">
  
      <div class="motherBox">
  
        <div class="leftBox" @click="scrollLeft">
          <div class="theChevBox">
            <i class="bi bi-chevron-left theChev"></i>
          </div>
        </div>
  
        <div class="centerBox"></div>
  
        <div class="rightBox" @click="scrollRight">
          <div class="theChevBox">
            <i class="bi bi-chevron-right theChev"></i>
          </div>
        </div>
  
      </div>
  
      <div class="picContentContainer" ref="picContainer">
        <div
          v-for="(cake) in ueeddingStore.ringPics"
          :key="cake.id"
          class="imgItem"
        >
          <img class="thePic" :src="require(`@/assets/rings/${cake.img_name}.webp`)" />
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref } from 'vue';
  import { useWeddingStore } from '@/stores/WeddingStore';
  
  const ueeddingStore = useWeddingStore();
  const picContainer = ref(null);
  
  const scrollLeft = () => {
    if (picContainer.value) {
      picContainer.value.scrollBy({
        left: -200, // Adjust the scroll amount as needed
        behavior: 'smooth'
      });
    }
  };
  
  const scrollRight = () => {
    if (picContainer.value) {
      picContainer.value.scrollBy({
        left: 200, // Adjust the scroll amount as needed
        behavior: 'smooth'
      });
    }
  };
  </script>
  
  <style scoped>
  .picContentContainer {
    position: relative;
    z-index: 1;
    display: flex;
    width: 100%;
    padding: 10px;
    flex-wrap: nowrap;
    overflow: hidden;
    transition: transform 0.3s ease; /* Add transition for smooth scrolling */
  }
  
  .imgItem {
    width: 200px;
    height: 400px;
    margin: 5px;
    flex: 0 0 auto;
  }
  
  .thePic {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  
  .motherBox {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    display: flex;
    width: 100%;
    height: 420px;
  }
  
  .leftBox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    height: 100%;
  }
  
  .centerBox {
    width: 60%;
    background-color: transparent;
    height: 100%;
  }
  
  .rightBox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    height: 100%;
  }
  
  .theChevBox {
    width: 50px;
    height: 50px;
    padding: 0;
    border-radius: 50%;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .theChev {
    font-size: 20px; /* Adjust size as needed */
    font-weight: bolder;
    color: white;
  }
  </style>
  