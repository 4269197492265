import apiClient from "./MyClient";

export default { 
  

 

  registerUser(formData){
    return apiClient.post('/api/general/register', formData);
  },


  loginUser(formData){
    return apiClient.post('/api/general/login', formData);
  },

  logout(){
    return apiClient.post('/api/logout/user/');
  },


  getUserDetails(){

    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;

    return apiClient.get("/api/user/info"); 

  },


  buyIntentNotify(){ 

    const authStore = localStorage.getItem("authStore");
    apiClient.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(authStore).token;

    return apiClient.get("/api/buy/intent"); 

  }


 
 

};
