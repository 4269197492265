<template>
    <div class="topNavBar">

        <div class="navItem"></div>

        <div class="navItem">
            <img class="imgLogo" :src="require(`@/assets/logo_trans.png`)" />
        </div>

        <div class="navItem">

            <div class="profilePic">



                <i @click="authStore.openMenuModal" class="bi bi-person-circle"
                    style="font-size: 30px; color: #959795;"></i>

            </div>

        </div>







    </div>



    <div v-if="authStore.showMenuModal" class="menuBGWrapper" @click="authStore.closeMenuModal">

        <div class="menuContent" @click.stop>

            <div class="closeBox">

                <div class="leftB">

                </div>

                <div class="rightB">

                    <i @click="authStore.closeMenuModal" class="bi bi-x-lg"
                                style="font-size: 20px; cursor: pointer"></i> 

                </div>

                
            </div>


            

            <div class="menuItem"> 
                <router-link :to="{ name: 'MyAccount' }" style="text-decoration: none; color: inherit;">
                    My Account
                </router-link>
            </div>

            <div class="menuItem" @click="authStore.logoutUser">
              Logout
            </div>

        </div>
    </div>


</template>

<script setup>
import { useAuthStore } from '@/stores/AuthStore';

const authStore = useAuthStore();





</script>

<style scoped>
.topNavBar {

    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    display: flex;
    justify-content: space-around;
    /* Evenly space out the nav items */
    align-items: center;
    /* Vertically center the items */
    position: fixed;
    /* Fix the nav bar to the top */
    top: 0;
    /* Align to the top */
    left: 0;
    width: 100%;
    /* Full width */
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    /* background-color: white; */
    padding: 10px 0;
    /* Some padding on top and bottom */
    z-index: 1;
    /* Ensure the navbar is above other content */

}

.navItem {
    text-align: center;
    /* Center the text inside each nav item */
    flex-grow: 1;
    /* Each nav item takes up equal space */
    /* background-color: rgb(201, 186, 186); */
}

.content {
    padding-top: 60px;
    /* Adjust as needed, to ensure the content doesn't go under the nav bar */
    /* Rest of your content styling */
}

.imgLogo {
    width: 90px;
}


.usernameBox,
.downArrow,
.profilePic {
    display: flex;
    align-items: center;
    /* Center content of each box vertically */
    justify-content: flex-end;
    /* Center content of each box horizontally */
    margin: 0 5px;
    /* Optional: add some space between the items */
}

/* Additional specific styles for each class if needed */
.profilePic {
    font-size: 30px;
    color: #959795;
    margin-right: 10px;
    cursor: pointer;
}


.roundProfilePic {
    border-radius: 60%;
    border: 1.5px solid #c3d4cb;
    object-fit: cover;
    /* Ensures the image covers the area without stretching */
    width: 35px;
    /* Set the width as required */
    height: 35px;
    /* Set the height as required */
}

.menuBGWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Black transparent background */
    z-index: 9999;
    /* High z-index to be on top of everything */
    display: flex;
    align-items:flex-start;
    justify-content: center;
    padding: 20px;
}

/* Menu Content */
.menuContent {
    width: 100%;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
}

/* Menu Item */
.menuItem {
    padding: 10px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
}

.menuItem:hover{
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    font-weight: bold;
}

.closeBox{
    display: flex;
    justify-content: space-between;
    /* background-color: red; */
    width: 100%;
    margin-bottom: 15px;
}




</style>
