<template>
    <div class="mainWrapper">


        <MainSideBar v-if="windowWidth > mobileWidthThreshold" />

        <div v-else>

            <MobileTopNav />

            <MobileBottomNav />

        </div>







        <div class="rightContentWrapper"
            :style="windowWidth <= mobileWidthThreshold ? 'width: 100%; padding-top: 40px;' : ''">


            <div class="topNav">
                <TopBar v-if="windowWidth > mobileWidthThreshold" />
            </div>


            <div class="innerContainer">


       


                <div class="blocksWrapper">

                    <div class="blockItem" v-if="authStore.userInfo && authStore.userInfo.plan_usage
                    ">

                        <div class="blockHeader">

                            Credits

                        </div>

                        <div class="blockbody">

                            <div class="lineItem">

                                Available credits: {{ authStore.userInfo.plan_usage.total_credits }}
                                

                            </div>

                            <div class="lineItem">

                         


                                <button @click="weddingStore.userBuyIntent" class="btn btn-dark" style="width: 100%; margin-top: 20px;"><b>Buy more credits</b></button>


                                </div>


                            
                         

                        </div>



                    </div>

                    <div class="blockItem">

                        <div class="blockHeader">

                            Account Details

                        </div>

                        <div class="blockbody" v-if="authStore.userInfo">

                            <div class="lineItem">

                                Name: {{ authStore.userInfo.name }}
                            </div>

                            <div class="lineItem">

                                Email:  {{ authStore.userInfo.email }}

                            </div>

                        </div>



                    </div>

                    

                </div>




            </div>






        </div>





    </div>
</template>

<script setup>

import { ref, onMounted, onUnmounted } from 'vue';
import MainSideBar from '@/components/MainSideBar.vue';
import MobileBottomNav from '@/components/MobileBottomNav.vue';
import MobileTopNav from '@/components/MobileTopNav.vue';
import TopBar from "@/components/TopBar.vue";
import { useAuthStore } from '@/stores/AuthStore';
import { useWeddingStore } from '@/stores/WeddingStore';


const authStore = useAuthStore();
const weddingStore = useWeddingStore();



const windowWidth = ref(window.innerWidth);
const mobileWidthThreshold = 1230; // Example threshold for mobile width, adjust as needed


const onResize = () => {
    windowWidth.value = window.innerWidth;
};








onMounted(() => {

    window.addEventListener('resize', onResize);


    authStore.fetchUserInfo()

});

onUnmounted(() => {
    window.removeEventListener('resize', onResize);
});



</script>

<style scoped>
.mainWrapper {
    /* background-color: rgb(118, 183, 240); */
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.rightContentWrapper {
    width: 83%;
    display: flex;
    flex-direction: column;
    /* background-color: bisque; */
}

.innerContainer {
    /* margin-top: 200px; */
    /* background-color: rgb(218, 210, 226); */
    overflow-y: auto;
    padding-top: 50px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 100px;

}

.theHeaderWrapper {
    display: flex;
    justify-content: space-between;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    /* background-color: aqua; */
}

.searchBarWrapper {
    margin-top: 15px;
    width: 100%;
    padding-bottom: 20px;
    /* background-color: rgb(22, 166, 37); */
}


.theBar {
    max-width: 350px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
}

.tableWrapper {
    margin-top: 20px;
    /* background-color: aqua; */
    width: 100%;
}

.my-custom-table tbody tr td {
    padding-top: 20px;
    /* Adjust the value as needed */
    padding-bottom: 20px;
    /* Adjust the value as needed */
}

.my-custom-table th {
    padding-bottom: 20px;
}

.my-custom-table th,
.my-custom-table td {
    text-align: left;
    /* Aligns text to the left */
    padding-left: 20px;
    padding-right: 20px;
}


.rowWrapper {
    display: flex;
    flex-direction: row;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    /* background-color: bisque; */
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    width: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 20px;
    cursor: pointer;
    text-decoration: none;
    color: initial;
}

.rowWrapper:hover {
    background-color: rgb(243, 245, 244);
}

.talkTitle {
    /* background-color: antiquewhite; */
    text-align: left;
    font-weight: bold;
}

.bottomPart {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    width: 100%;
    /* background-color: rgb(215, 215, 230); */
    margin-top: 10px;
}

.iconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}


.signedPart {
    /* background-color: antiquewhite; */
    font-size: 13px;
    text-align: left;
    margin-right: 20px;
}

.datePart {
    /* background-color: antiquewhite; */
    font-size: 13px;
    text-align: left;
}

.theTitle {
    width: 100%;
    margin-bottom: 20px;

    /* background-color: gray; */
}

.theFormBox {
    display: flex;
    justify-content: center;
    width: 100%;
    /* background-color: yellow; */
}

.genCountBox {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    /* background-color: yellow; */
}

.theInput {
    border: 1.5px solid rgb(103, 103, 103);
}

.theSubmitBtn {
    width: 100%;
    background: linear-gradient(90deg, #FC466B 0%, #3F5EFB 100%);
    color: white;
    border: none;
    padding: 18px;
}


.theImgList {
    width: 100%;
    /* background-color: antiquewhite; */
    margin-top: 60px;
}

.ringHead {
    text-align: left;
    font-weight: bolder;
    font-size: 18px;
    margin-bottom: 20px;
}

.theImgWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    /* background-color: aquamarine; */
}

.imgItem {
    /* background-color: red; */
    border-radius: 10px;
    flex: 0 0 calc(16.66% - 10px);
    /* 6 items per row */
    margin: 5px;
    overflow: hidden;
    /* Hide overflow */
    position: relative;
    /* Ensure positioning context */
    aspect-ratio: 1 / 1;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.imgItem img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Scale image to cover container */
    object-position: center;
    /* Center the image */
    display: block;
    /* Remove default inline spacing */
}

/* .imgItem {
    background-color: red;
    border-radius: 10px;
    height: 200px;
    flex: 0 0 calc(16.66% - 10px); 
    margin: 5px;
} */

@media (max-width: 1230px) {
    .imgItem {
        flex: 0 0 calc(50% - 10px);
        /* 2 items per row */
    }
}

.theFormWrapper {
    width: 500px;
}


.theGIFBox {
    display: flex;
    width: 100%;
    justify-content: center;
    /* background-color: yellow; */
}

.theCakeLoadingImg {
    width: 100px;
}



.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    z-index: 999;
    /* Ensure the modal is on top */
}

/* Styles for the modal box */
.modal-box {
    background-color: white;
    width: 1100px;
    padding: 20px;
    border-radius: 2px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal-content {
    text-align: center;
}

.model-box-header {
    display: flex;
    justify-content: space-between;
    /* background-color: antiquewhite; */
    height: 40px;
    margin-bottom: 15px;
}

.buttonOptionsWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    /* background-color: red; */
    margin-top: 15px;
    padding: 20px;
}

.theButtonBox {
    display: flex;
    justify-content: space-between;
    /* background-color: purple; */
    width: 100%;
}

.resultsBox {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 20px;


    /* background-color: rgb(137, 158, 177); */
}

.theRingImg {
    width: 100%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}


.blocksWrapper {
    display: flex;
    flex-wrap: wrap;
    /* Allows items to wrap to the next line */
    width: 100%;
    /* background-color: yellow; */
}

.blockItem {
    flex: 1 1 calc(50% - 20px);
    /* Two items per row */
    max-width: calc(50% - 20px);
    box-sizing: border-box;
    height: 200px;
    margin: 10px;
    /* Spacing between items */
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;

}

/* Mobile Styles */
@media (max-width: 768px) {
    .blockItem {
        flex: 1 1 100%;
        /* One item per row */
        max-width: 100%;
    }
}


.blockHeader {
    height: 20%;
    background-color: #f2f2f2;
    font-weight: bolder;
    text-align: left;
    padding: 10px;
}


.blockbody {
    height: 80%;


}

.lineItem {
    width: 100%;
    text-align: left;
    padding: 15px;
    font-size: 16px;
}
</style>